/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import HeroCard from "../components/heroCard"
import CardListItem from "../components/cardListItem"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Box } from "theme-ui"
import { Link } from "gatsby"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMdx.nodes

  if (posts.length === 0) {
    return (
      <Layout location={location} wrapper="global-wrapper" title={siteTitle}>
        <Seo title="All posts" />
        <HeroCard />
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle} wrapper="global-wrapper">
      <Seo title="All posts" />
      <Box
        sx={{
          zIndex: 1000,
          position: "relative",
        }}
      >
        <Box
          sx={{
            padding: [
              "3.75em 1rem 0 1rem",
              "3.75em 1rem 0 1rem",
              "3.75em 1rem 0 1rem",
              "3.75em 4rem 0 4rem",
            ],
            backgroundColor: "background",
          }}
        >
          <CardListItem posts={posts} />
          <Box pl={1}>
            <Link to="/all/" variant="primary">
              Read all posts
            </Link>
          </Box>
        </Box>
      </Box>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      filter: { fileAbsolutePath: { regex: "/content/blog/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          featuredImage {
            image {
              relativePath
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            credit
            creditUrl
          }
        }
      }
    }
  }
`
